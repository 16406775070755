import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';

function Notfound() {
  return (
    <Container>
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Welcome to OptimaTune
        </Typography>
        <Typography variant="h6" color="textSecondary" paragraph>
          This page does not exist.
        </Typography>
      </Box>
    </Container>
  );
}

export default Notfound;
