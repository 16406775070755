import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Tab, Tabs, Typography, Paper, CardContent, CircularProgress, Grid, Card, CardMedia, CardActions, Link, Button } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import ReactMarkdown from 'react-markdown';

function ViewResult() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState('3');
  const [products, setProducts] = useState([]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const safeAccess = (obj, path) => {
    return path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), obj);
  };

  const params = useParams();

  useEffect(() => {
    fetch('https://api.optimatune.com/products')
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error('Failed to fetch products:', error));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const systemInfoResponse = await fetch(`https://api.optimatune.com/info/get/${params.id}`);
        const systemInfoData = await systemInfoResponse.json();
        setData(systemInfoData);

        const gptResponse = await fetch(`https://api.optimatune.com/results/get/${params.id}`);
        const gptData = await gptResponse.json();

        setData(prevData => ({ ...prevData, gptData })); // Combine system info and GPT data

        const isPending = gptData.some(result => result.ResultStatus === "Pending");
        if (isPending) {
          setTimeout(fetchData, 1000);
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [params.id]);

  const isDataLoaded = data && Array.isArray(data.gptData);

  // Default values to avoid undefined error
  const upgradeRecsData = isDataLoaded ? data.gptData.find(result => result.PromptType === 'UpgradeRecommendations') : null;
  const letterGradesData = isDataLoaded ? data.gptData.find(result => result.PromptType === 'GetLetterGrades') : null;
  const performanceAnalysisData = isDataLoaded ? data.gptData.find(result => result.PromptType === 'PerformanceAnalysis') : null;

  if (!data || !isDataLoaded || loading) {
    return (
      <Box>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <Typography variant="h4" align="center" gutterBottom>
            Results are being processed, this may take a few seconds
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  const { networkResults, motherboardInfo, cpuInfo, gpuInfo, ramInfo } = data;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <Typography variant="h4" align="center" gutterBottom>
            System Overview
          </Typography>

          <Card variant="outlined" sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6">Network</Typography>
            <Typography>{networkResults ? `${networkResults.downloadSpeed.toFixed(2)} Mbps ↓ / ${networkResults.uploadSpeed.toFixed(2)} Mbps ↑` : 'Data not available'}</Typography>

            <Typography variant="h6">Motherboard</Typography>
            <Typography>{motherboardInfo ? `${motherboardInfo.vendor} ${motherboardInfo.product}` : 'Data not available'}</Typography>

            <Typography variant="h6">CPU</Typography>
            <Typography>{cpuInfo && cpuInfo.cpus && cpuInfo.cpus[0] ? cpuInfo.cpus[0].modelName : 'Data not available'}</Typography>

            <Typography variant="h6">GPU</Typography>
            <Typography>{gpuInfo && gpuInfo.gpus ? gpuInfo.gpus.map(gpu => `${gpu.vendor} ${gpu.product}`).join(', ') : 'Data not available'}</Typography>

            <Typography variant="h6">RAM</Typography>
            <Typography>{ramInfo ? `${ramInfo.total} MB Total` : 'Data not available'}</Typography>
          </CardContent>
        </Card>

          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChange} aria-label="results tabs">
                <Tab label="Performance Analysis" value="3" />
                <Tab label="Upgrade Recommendations" value="1" />
                <Tab label="Component Grades" value="2" />
              </Tabs>
            </Box>
            <TabPanel value="1">
              <Paper elevation={3} sx={{ p: 2 }}>
                <ReactMarkdown>
                  {upgradeRecsData ? upgradeRecsData.AIResponse : 'Loading...'}
                </ReactMarkdown>
              </Paper>
            </TabPanel>
            <TabPanel value="2">
              <Paper elevation={3} sx={{ p: 2 }}>
                <ReactMarkdown>
                  {letterGradesData ? letterGradesData.AIResponse : 'Loading...'}
                </ReactMarkdown>
              </Paper>
            </TabPanel>
            <TabPanel value="3">
              <Paper elevation={3} sx={{ p: 2 }}>
                <ReactMarkdown>
                  {performanceAnalysisData ? performanceAnalysisData.AIResponse : 'Loading...'}
                </ReactMarkdown>
              </Paper>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h6" gutterBottom>
          Upgrades Picked For You
        </Typography>
        {products.map((product, index) => (
          <Link href={product.link} target="_blank" rel="noopener noreferrer" underline="none">
            <Card key={index} sx={{ mb: 2, boxShadow: 3, '&:hover': { boxShadow: 6 } }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {product.name}
                </Typography>
                <CardMedia
                  component="img"
                  height="140"
                  image={product.imageUrl}
                  alt={product.name}
                />
                <Typography variant="body2" color="text.secondary">
                  {product.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary">
                  View on Amazon
                </Button>
              </CardActions>
            </Card>
          </Link>
        ))}
      </Grid>
      <Typography variant="caption" color="text.secondary">
    (Some links on this page may contain affiliate tracking)
  </Typography>
    </Grid>
    
  );
}

export default ViewResult;
