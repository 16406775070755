import React from 'react';
import ReactGA from "react-ga4";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Typography, Button, Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';

import { Link } from 'react-router-dom';
import ViewResult from './components/ViewResult';
import Home from './components/Home';
import Notfound from './components/Notfound';
import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#34568B', // a deep, classic blue
    },
    secondary: {
      main: '#88B04B', // a muted, sage green
    },
    info: {
      main: '#6A7F98', // a soft, greyish blue
    },
    success: {
      main: '#5F9279', // a muted green
    },
    warning: {
      main: '#FFC107', // a muted gold
    },
    error: {
      main: '#D9534F', // a subdued red
    },
    background: {
      default: '#F4F5F7', // a very light grey, almost white
      paper: '#FFFFFF', // pure white
    },
    text: {
      primary: '#212529', // a very dark grey, almost black
      secondary: '#6C757D', // a medium-dark grey
    },
  },
});


ReactGA.initialize('G-R25SFSTBCG');

function App() {
  const TrackPageView = () => {
    const location = useLocation();
    console.log(location)

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

    return null;
  };
  const fileUrl = 'https://optimatune-public.s3.amazonaws.com/OptimaTune.zip';
  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <TrackPageView />
        <AppBar position="static" color="primary">
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap component={Link} to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              OptimaTune
            </Typography>
            <div style={{ flexGrow: 1 }} />
            <Button color="inherit" href={fileUrl} download="OptimaTune.zip">
              Download
            </Button>
          </Toolbar>
        </AppBar>
        <Container component="main" sx={{ mt: 4, mb: 4, maxWidth: '100vh' }}> {/* Adjust maxWidth as needed */}
          <Paper elevation={12} sx={{ p: 1, minHeight: '80vh', backgroundColor: theme.palette.background.default }}>
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/view-result/:id" element={<ViewResult />} />
              <Route path="*" element={<Notfound />} />
            </Routes>
          </Paper>
        </Container>
      </Router>
    </ThemeProvider>
  );
}

export default App;
