import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Container } from '@mui/material';

function Home() {
  const fileUrl = 'https://optimatune-public.s3.amazonaws.com/OptimaTune.zip';
  const [userRequestCount, setUserRequestCount] = useState(null);

  useEffect(() => {
    fetch('https://api.optimatune.com/counter/UserRequestCount')
      .then(response => response.json())
      .then(data => setUserRequestCount(data.count))
      .catch(error => console.error('Error fetching user request count:', error));
  }, []);
  return (
    <Container>
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Welcome to OptimaTune
        </Typography>
        <Typography variant="h6" color="textSecondary" paragraph>
          Your go-to AI-powered computer analysis tool.
        </Typography>
        <Typography variant="body1" paragraph>
          With OptimaTune, you can effortlessly analyze your computer's performance. Our advanced AI algorithms will examine your system's specifications and provide comprehensive insights into its capabilities. Here's how you can get started:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Step 1:</strong> Download the OptimaTune tool from our website.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Step 2:</strong> Run OptimaTune to let it analyze your computer's hardware and software configurations.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Step 3:</strong> Review the detailed analysis and recommendations provided by OptimaTune to optimize your system's performance.
        </Typography>
        <a href={fileUrl} download style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
            Download OptimaTune
          </Button>
        </a>
        {userRequestCount !== null && (
          <Typography sx={{paddingTop: "10vh"}}variant="h6" color="textSecondary" paragraph>
          {userRequestCount} Systems Analyzed And Counting!
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default Home;
